<template>
  <div class="tax-data">
    <h3>{{ $t('onboarding.tax_data.title') }}</h3>

    <h4>{{ $t('onboarding.tax_data.your_company_data') }}</h4>
    {{ $t('onboarding.tax_data.text') }}

    <b-form @submit.prevent="onSubmit">

      <b-row class="m-t-md">
        <b-col class="text-center" cols="12">
          <generic-input type="file" multiple :accept="acceptedFileFormats" @input="(file) => files.push(file)" max-file-size="25000000">
            <div class="input-file-box">
              {{ $t('common.business_license') }}/<br>
              {{ $t('common.commercial_registration_proof') }}<br>
              <font-awesome-icon icon="upload" class="m-t"/>
            </div>
          </generic-input>
        </b-col>
        <b-col v-for="file in currentUserImages" cols="12">
          {{ file.filename }}
        </b-col>
        <b-col v-for="(file,index) in files" cols="12">
          {{ file.name }}
          <b-button class="no-borders" variant="default"
                    @click.prevent="$delete(files, index)">
            <font-awesome-icon icon="times-circle"/>
          </b-button>
        </b-col>
      </b-row>

      <b-row class="m-t-md" v-for="list in inputs">
        <b-col v-for="input in list" v-bind="$compute(input.col)"
               v-if="input.visible===undefined || $compute(input.visible)">
          <generic-input v-bind="input" :value="$g(form, input.key)"
                         @input="(event) => $s(form, input.key, event)"/>
        </b-col>
      </b-row>

      <b-button class="m-t m-b block full-width" type="submit" variant="primary" :disabled="!isSubmitButtonEnabled">
        {{ $t('button.save') }}
      </b-button>

    </b-form>
  </div>
</template>

<script>
import GenericInput from "@/components/generic_input.vue";
import {mapGetters} from "vuex";
import FileInput from "@/components/file_input.vue";
import {putOnboardingClient} from "@/services/onboarding";
import store from "@/store";
import {getEmployee} from "@/services/employees";
import {EventBus} from "@/eventbus";

export default {
  components: {FileInput, GenericInput},
  beforeMount() {
    this.form.tax_number = this.current_user.client.tax_number
    this.form.vat_number = this.current_user.client.vat_number
    this.form.not_taxable = !this.current_user.client.taxable
  },
  data() {
    return {
      acceptedFileFormats: ["jpeg", "jpg", "png", "pdf"],
      inputs: [
        [
          {
            key: 'tax_number',
            label: 'common.tax_number',
            placeholder: '12/345/67',
            required: true
          }
        ],
        [
          {
            key: 'vat_number',
            label: 'common.vat_number',
            placeholder: 'DE000012',
            required: false
          }
        ],
        [
          {
            key: 'not_taxable',
            label: 'onboarding.tax_data.not_taxable',
            type: 'checkbox',
            required: false
          }
        ]
      ],
      files: [],
      form: {
        tax_number: null,
        vat_number: null,
        taxable: true,
        not_taxable: false,
        business_license_images: []
      }
    }
  },
  watch: {
    'form.not_taxable': {
      immediate: true,
      handler(oldV, newV) {
        // apply reversed boolean check
        this.form.taxable = !this.form.not_taxable
      }
    }
  },
  computed: {
    ...mapGetters("user", ["current_user"]),
    currentUserImages() {
      return this.current_user.client.business_license_images
    },
    isSubmitButtonEnabled() {
      return this.currentUserImages.length > 0 || this.files.length > 0
    }
  },
  methods: {
    async onSubmit(event) {
      // trigger progress spinner to include processing time of base64 parsing
      EventBus.$emit("toggleProgressSpinner", true)
      this.form.business_license_images.splice(0)
      for (const file of this.files) {
        await this.$parseFileToBase64(file).then(base64 => {
          this.form.business_license_images.push(
              {
                filename: file.name,
                file_base64: base64
              }
          )
        }).catch(error => {
          // TODO: handle error
        })
      }
      putOnboardingClient(this.current_user.client.id, this.form).then(response => {
        // download user to reflect changes
        getEmployee().then(response => {
          store.dispatch("user/setUser", response)
          this.$router.push({name: 'onboarding_dashboard'})
        })
      }).catch()
    },
  }
}
</script>

<style scoped lang="scss">

</style>


